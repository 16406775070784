<template>
<div class="imagelazy">
    <h1>photo</h1>

</div>
</template>

<script>

export default {
  name: 'ImageLazy',
  components: {
  }
}
</script>


