<template>
  <div class="about">
    <h1>Photo Test Page</h1>
    <ImageLazy/>
  </div>

</template>

<script>
// @ is an alias to /src
import ImageLazy from '@/components/ImageLazy.vue'

export default {
  name: 'Photography',
  components: {
    ImageLazy
  }
}
</script>

<style>
</style>
